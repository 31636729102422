import { Link } from "gatsby";
import React, { Component } from "react";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false
    };

    
  }
  componentDidMount() {
    const navbar = document.querySelector('#header');
    console.log(navbar);
    window.onscroll = () => {
        if (window.scrollY > 75) {
            navbar.classList.add('nav-active');
        } else {
            navbar.classList.remove('nav-active');
        }
    };
  }

  render() {
    const { data, header } = this.props;
    const { menu } = this.state;
    return (
      <header id="header" className={`site-header ${menu ? "active" : ""}`}>
        <div className="container">
          <div className="header-main">
            <div className="logo">
              <Link to="/">
                {data.logo.file.url ? (
                  <img style={{ maxHeight: "25px" }} src={data.logo.file.url} alt="logo" />
                ) : (
                  <span>{data.siteName}</span>
                )}
              </Link>
            </div>
            <div
              className="responsive-menu"
              onClick={() => {
                this.setState({
                  menu: !menu
                });
              }}
            >
              <span></span>
            </div>
            {header === "home" ? (
              <div className="menu">
                <ul
                  onClick={() => {
                    this.setState({
                      menu: false
                    });
                  }}
                >
                  {/* <li key="home">
                    <Link to="/#home">Home</Link>
                  </li> */}
                  {data.menus
                    .filter(item => item === "About")
                    .map(t => {
                      return (
                        <li key="Rólam">
                          <Link to={`/#Rólam`}>Rólam</Link>
                        </li>
                      );
                    })}
                  {data.menus
                    .filter(item => item === "Service")
                    .map(t => {
                      return (
                        <li key="Szolgáltatások">
                          <Link to={`/#Szolgáltatások`}>Szolgáltatások</Link>
                        </li>
                      );
                    })}
                  {/* {data.menus
                    .filter(item => item === "Blogs")
                    .map(t => {
                      return (
                        <li key="Blogs">
                          <Link to={`/#Blogs`}>Blogs</Link>
                        </li>
                      );
                    })} */}

                  {data.menus
                    .filter(item => item === "Work")
                    .map(t => {
                      return (
                        <li key="Megjelenések">
                          <Link to={`/#Megjelenések`}>Megjelenések</Link>
                        </li>
                      );
                    })}
                  {/* {data.menus
                    .filter(item => item === "Testimonials")
                    .map(t => {
                      return (
                        <li key="Testimonials">
                          <Link to={`/#Testimonials`}>Testimonials</Link>
                        </li>
                      );
                    })} */}
                  {data.menus
                    .filter(item => item === "Photos")
                    .map(t => {
                      return (
                        <li key="Sajtóanyagok">
                          <Link to={`/#Sajtóanyagok`}>Sajtóanyagok</Link>
                        </li>
                      );
                    })}
                  {data.menus
                    .filter(item => item === "Contact")
                    .map(t => {
                      return (
                        <li key="Kapcsolat">
                          <Link to={`/#Kapcsolat`}>Kapcsolat</Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
            ) : (
              <div className="menu">
                <ul
                  onClick={() => {
                    this.setState({
                      menu: false
                    });
                  }}
                >
                  <li key="home">
                    <Link to="/#home">Főoldal</Link>
                  </li>
                  {/* {data.menus
                    .filter(item => item === "Blogs")
                    .map(t => {
                      return (
                        <li key="blogs">
                          <Link to="/blogs">Blogs</Link>
                        </li>
                      );
                    })} */}
                  {/* {data.menus
                    .filter(item => item === "Photos")
                    .map(t => {
                      return (
                        <li key="photos">
                          <Link to="/photos">Photos</Link>
                        </li>
                      );
                    })} */}
                </ul>
              </div>
            )}
          </div>
        </div>
      </header>
    );
  }
}
